<template>
  <v-card @click="redirectToFair" class="rounded-b-lg mb-4" elevation="5">
    <v-img
      :src="imageUrl"
      @error="isErrorOnLoadFairImage = true"
      height="auto"
    />
    <v-card-actions class="rounded-b-lg fair__name white--text">
      <span>{{ fair.name }}</span>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
.fair__name {
  background: $secondary-color;
}
</style>

<script>
import { BASE_URL_FISEWEB } from "@/constants/ServicesConstants";

export default {
  name: "ItemListFairs",
  props: ["index"],
  data() {
    return {
      defaultImage: require("@/assets/images/fairs/fair-default.png"),
      isErrorOnLoadFairImage: false,
    };
  },
  methods: {
    redirectToFair() {
      this.setFairSelected();
      if (
        this.$store.getters["auth/authenticated"] &&
        this.$store.getters["auth/userInfo"].user.fair.id !== this.fair.id
      ) {
        this.$store.dispatch("auth/logout");
      }
      this.$router
        .push({ name: "fair", params: { slug: this.fair.slug } })
        .catch(() => {});
    },
    setFairSelected() {
      this.$store.dispatch("fairs/setFairByIndex", this.index);
    },
  },
  computed: {
    totalFairs() {
      return this.$store.getters["fairs/total"];
    },
    fair() {
      return this.$store.getters["fairs/fairByIndex"](this.index);
    },
    imageUrl() {
      if (this.isErrorOnLoadFairImage) {
        return this.defaultImage;
      } else {
        return `${BASE_URL_FISEWEB}${this.fair.imageUrl ?? null}`;
      }
    },
  },
};
</script>
